import { useSearchParams } from 'next/navigation';
import { useCallback, useMemo } from 'react';

import { usePathname } from '@/i18n/navigation';

import { useRouter } from './useRouter';

type QueryParams = Record<string, string | string[] | number | undefined>;

export default function useQueryParams<T extends QueryParams>() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queryParams = useMemo(() => {
    if (searchParams == null) return {};

    const entries = searchParams.entries();
    const result: QueryParams = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }, [searchParams]);

  const updateQuery = useCallback(
    (newParams: Partial<T>) => {
      if (searchParams == null) return {};

      const current = new URLSearchParams(window.location.search);

      Object.keys(newParams).forEach((key) => {
        const value = newParams[key];
        if (value === undefined || value === null) {
          current.delete(key); // Remove param if undefined or null
        } else if (Array.isArray(value)) {
          current.delete(key);
          value.forEach((val) => current.append(key, val));
        } else {
          current.set(key, value.toString());
        }
      });

      if (current.size === 0) {
        clearQuery();
      }

      router.replace(`?${current.toString()}`, { scroll: false });
    },
    [searchParams]
  );

  const clearQuery = useCallback(() => {
    window.history.replaceState(null, '', pathname);
  }, [pathname]);

  return {
    queryParams: queryParams as T,
    updateQuery,
    clearQuery,
  };
}
